import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Ancillary = ({ email, services, telephone, telephoneLink }) => (
    <aside className={"relative overflow-hidden ancillary"}>

        <div className={"bg-secondary ancillary-cta-bar bg-cover"}>
            <div
                className="max-w-screen-xl mx-auto py-12 px-6 md:px-12 lg:py-16 lg:flex lg:items-center lg:justify-between">
                <h2 className="lg:flex-auto lg:pr-6 text-3xl leading-9 tracking-tight font-display text-white sm:text-4xl sm:leading-10">
                    Ready to talk?
                    <br/>
                    <span className="text-gray-300">Contact us, whatever your query.</span>
                </h2>
                <div className="lg:flex-auto mt-8 lg:mt-0">
                    <div className="flex lg:flex-col">
                        <Link
                          to={'/contact'}
                          className={"flex-1 xl:flex-none lg:mb-2 font-sans uppercase font-black rounded-md shadow text-primary hover:text-secondary text-center whitespace-no-wrap px-5 py-3 border border-transparent text-base leading-6 rounded-md bg-white hover:bg-gray-100 focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"}
                        >Contact us</Link>
                    </div>
                </div>
            </div>
        </div>

        <div className={"bg-gray-800"}>
            <div className={"max-w-screen-xl mx-auto py-12 lg:py-16 px-6 md:px-12"}>
                <div className={"grid grid-cols-12"}>
                    <div className={"col-span-12 mb-6 md:mb-8 lg:col-span-5 lg:mb-0"}>
                        <h2 className={"text-gray-400 leading-8 sm:leading-10 text-base font-sans font-black uppercase"}>About</h2>
                        <p className={"text-white prose prose-lg"}>
                            PKD services enrich and enable new business processes, new markets and customer interactions. We can help your business to take advantage of new technology to increase business relevance.
                            Let the experienced team at PKD advise you on the best technology solutions based upon your organisation's needs and budget.
                        </p>
                    </div>

                    <div className={"col-span-12 mb-6 sm:col-span-6 sm:mb-0 lg:col-span-3 lg:col-start-7"}>
                        <h2 className={"text-gray-400 leading-8 sm:leading-10 text-base font-sans font-black uppercase"}>Services</h2>
                        <ul className={"text-lg"}>
                            {services.map(link => (
                                <li className={"mb-1 text-white pkd-list pkd-list-dark"} key={link.title}>{link.title}</li>
                            ))}
                        </ul>
                    </div>

                    <div className={"col-span-12 sm:col-span-6 lg:col-span-2 lg:col-start-10"}>
                        <h2 className={"text-gray-400 leading-8 sm:leading-10 text-base font-sans font-black uppercase"}>Contact</h2>
                        <ul>
                            <li className={"mb-1"}>
                                <a href={telephoneLink} className={"text-white text-lg hover:text-secondary focus:text-secondary"}>{telephone}</a>
                            </li>
                            <li>
                                <a href={`mailto:${email}`} className={"text-white text-lg hover:text-secondary focus:text-secondary"}>{email}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </aside>
)

Ancillary.propTypes = {
    email: PropTypes.string,
    telephone: PropTypes.string,
    telephoneLink: PropTypes.string
}

export default Ancillary
