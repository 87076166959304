import { Link } from "gatsby"
import React from "react"
import ContextConsumer from "./context"
import { motion } from "framer-motion"

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.siteTitle = props.siteTitle;
        this.telephoneLink = props.telephoneLink;
        this.telephone = props.telephone;
        this.siteTitle = props.siteTitle;
        this.email = props.email;
        this.headerLinks = props.headerLinks;

        this.state = {isExpanded: false};
        this.toggleExpansion = value => {
            this.setState({
                isExpanded: value
            });
        }
    }

    render() {

        return (
            <motion.header className={"relative z-20 py-3 lg:py-8 px-6 md:px-12 mb-8 header"}
                    initial={{ y: '-50px', opacity: 0}}
                    animate={{ y: '0px', opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}>
                <nav className={"flex items-center justify-between flex-wrap"}>
                    <div className={"flex items-center justify-between w-full lg:w-auto"}>
                        <h1 className={"logo"}>
                            <ContextConsumer>{({ data, set }) => (
                            <Link to="/" title={"Return to homepage"}
                                  className={"block overflow-hidden whitespace-no-wrap bg-no-repeat bg-contain w-full h-full"}
                                  onClick={() => set({ currentPage: 'home' })}>{this.siteTitle}</Link>
                            )}</ContextConsumer>
                        </h1>

                        <button className={`block relative outline-none w-8 h-5 lg:hidden hamburger ${this.state.isExpanded ? 'active' : ''}`}
                                aria-controls="navbarSupportedContent" aria-expanded={this.state.isExpanded} aria-label="Toggle navigation" title="Navigation menu"
                                onClick={() => this.toggleExpansion(!this.state.isExpanded)}>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                        </button>
                    </div>

                    <div id={"navbarSupportedContent"}
                        className={`${this.state.isExpanded ? `block pt-5` : `hidden`} flex flex-col w-full lg:flex lg:flex-col-reverse lg:p-0 lg:w-auto`}>
                        <ContextConsumer>{({ data, set }) => (
                            <ul className={"flex flex-col lg:flex-row lg:justify-end primary-navigation"}>
                                {this.headerLinks.map((link, index) => (
                                    <li className={"lg:ml-10"} key={index}>
                                        <Link to={link.path}
                                              className={"block font-sans relative py-2 text-md uppercase font-black text-primary hover:text-secondary focus:text-secondary transition-colors duration-300 lg:border-0 lg:px-0 lg:pt-0"}
                                              activeClassName={"text-secondary"}
                                              partiallyActive={index === 0 ? false : true}
                                              data-index={index}
                                              onClick={() => {
                                                  set({ currentPage: link.title.toLowerCase() });
                                                  this.toggleExpansion(!this.state.isExpanded);
                                              }}
                                        >{link.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}</ContextConsumer>

                        <ul className={"flex flex-col pt-3 font-sans text-center xs:flex-row xs:justify-center lg:justify-end lg:mb-8 lg:pt-0 xl:mb-10"}>
                            <li className={"xs:mx-4 lg:m-0"}>
                                <a href={this.telephoneLink}
                                   className={"text-gray-700 lg:text-lg xl-text-xl"}>{this.telephone}</a>
                            </li>
                            <li className={"xs:mx-4 lg:mr-0 lg:ml-12 xl:ml-16"}>
                                <a href={`mailto:${this.email}`}
                                   className={"text-gray-700 lg:text-lg xl-text-xl"}>{this.email}</a>
                            </li>
                        </ul>

                    </div>

                </nav>

            </motion.header>
        )
    }
}

export default Header
