module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PKD Tech","short_name":"PKD Tech","start_url":"/","background_color":"#e6e6e6","theme_color":"#808080","display":"minimal-ui","icon":"src/images/logo-icon-touch.png","icon_options":{"purpose":"any maskable"},"include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"13fd230a4c2da622e07db0371333aeeb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/clients/pkd-tech/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
